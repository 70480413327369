<template>
  <b-card  >
      <v-tree
        class="p-0 m-0"
        ref="treeAsync"
        :data="treeDataAsync"
        :multiple="false"
        @node-check="nodechecked"
        @async-load-nodes="asyncLoad"
      />
  </b-card>
</template>

<script>

import {BCard, BCardHeader} from 'bootstrap-vue'
import { VTree, VSelectTree } from 'vue-tree-halower'
import 'vue-tree-halower/dist/halower-tree.min.css'
import userStoreModule from '../userStoreModule'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    VTree,
    VSelectTree,
    BCardHeader,
    BCard,
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    // const users = ref([])
    // const treeData = ref([])

    // //
    // // ** Construction of user hierarchy **
    // //
    // try {
    //   store.dispatch('app-user/fetchUsersAll')
    //   .then(response => { 
    //      var newArray = response.data.users.filter((item) => item.status == true )
    //       users.value = newArray
    //   })
    //   .then( () => {
    //     // the logged in user is level 
    //     console.log("users: ", users.value)
    //     const userLogged = JSON.parse(localStorage.getItem('userData'))
    //     var treeDataAux = {
    //       id: userLogged._id,
    //       title: userLogged.userName,
    //       expanded: true,
    //       children: []
    //     }
    //     treeData.value.push(treeDataAux)
               
    //    // the children of the logged in user are searched for level 1
        
    //     userLogged.children.forEach(child => {       
    //       const childData = users.value.find(element => element._id == child.userId)
    //       if (childData) {
    //          var treeDataAux = {
    //           id: childData._id,
    //           title: childData.userName,
    //           expanded: false,
    //           children: []
    //         }
    //         treeData.value[0].children.push(treeDataAux)
    //       }
    //     })

    //     // set up to level 5
    //     // the level 1 children is traversed to search for level 2
    //     for (var i = 0; i < treeData.value[0].children.length; i++) {  
    //       const childArray = users.value.find(element => element._id == treeData.value[0].children[i].id)
    //       if (childArray) {
    //         // if (childArray.children[0].userId != 'x') {
    //           childArray.children.forEach(child => {
    //             const user = users.value.find(element => element._id == child.userId)
    //             if (user) {
    //               var treeDataAux = {
    //                 id: child.userId,
    //                 title: user.userName,
    //                 expanded: false,
    //                 children: []
    //               }
    //               treeData.value[0].children[i].children.push(treeDataAux)
    //             }
    //           })
    //         // }
    //       }
    //        // the level 2 children is traversed to search for level 3
    //       for (var j = 0; j < treeData.value[0].children[i].children.length; j++) {
    //         const childArray = users.value.find(element => element._id == treeData.value[0].children[i].children[j].id)
    //         if (childArray) {
    //           childArray.children.forEach(child => {
    //             const user = users.value.find(element => element._id == child.userId)
    //             if (user){
    //               var treeDataAux = {
    //                 id: user.userId,
    //                 title: user.userName,
    //                 expanded: false,
    //                 children: []
    //               }
    //               treeData.value[0].children[i].children[j].children.push(treeDataAux)
    //             }
    //           })
    //         }
    //         // the level 3 children is traversed to search for level 4
    //         for (var k = 0; k < treeData.value[0].children[i].children[j].children.length; k++) {
    //           const childArray = users.value.find(element => element._id == treeData.value[0].children[i].children[j].children[k].id)
    //           if (childArray) {
    //             childArray.children.forEach(child => {
    //               const user = users.value.find(element => element._id == child.userId)
    //               if (user){
    //                 var treeDataAux = {
    //                   id: user.userId,
    //                   title: user.userName,
    //                   expanded: false,
    //                   children: []
    //                 }
    //                 treeData.value[0].children[i].children[j].children[k].children.push(treeDataAux)
    //               }
    //             })
    //           }
    //           // the level 4 children is traversed to search for level 5
    //           for (var l = 0; l < treeData.value[0].children[i].children[j].children[k].children.length; l++) {
    //             const childArray = users.value.find(element => element._id == treeData.value[0].children[i].children[j].children[k].children[l].id)
    //             if (childArray) {
    //               childArray.children.forEach(child => {
    //                 const user = users.value.find(element => element._id == child.userId)
    //                 if (user){
    //                   var treeDataAux = {
    //                     id: user.userId,
    //                     title: user.userName,
    //                     expanded: false,
    //                     children: []
    //                   }
    //                   treeData.value[0].children[i].children[j].children[k].children.push(treeDataAux)
    //                 }
    //               })
    //             }
    //           } //for l
    //         }  //for k
    //       }  // for j
    //     } // for i

    //   }) //end then
    // } catch (error) {
    //   console.error(error);
    // }
  },

  data: () => {
    return {
      initSelected: [],
       treeDataAsync: [],
      CopyTreeDataAsync: [
        {
          title: 'node1',
          expanded: false,
          async: true,
          children: [ // ---> Array users children of the user Betconnect 
              {
                  id: 'asdasdasd1', //----> id of the user
                  title: 'alfonso22541', // --> name user
                  expanded: false,
                  async: true,
                  
              },
              {
                  id: 'asdasdasd2', //----> id of the user
                  title: 'alfonso22542', // --> name user
                  expanded: false,
                  async: true,
                  
              },
              {
                  id: 'asdasdasd3', //----> id of the user
                  title: 'alfonso22543', // --> name user
                  expanded: false,
                  async: true,
                  
              },
              {
                  id: 'asdasdasd4', //----> id of the user
                  title: 'alfonso22544', // --> name user
                  expanded: false,
                  async: true, 
              },
          ],
        }
      ]
    }
  },
  computed: {
    userIdLogged: function () {
       const {_id} = JSON.parse(localStorage.getItem('userData'))
      return _id
    },
  },

  methods: {
    async limpiarData(array){
      try {
        return await array.map(element => {
          return {
            id: element.id,
            title: element.title,
            expanded: false,
            async: true,
          }
        })
      } catch (error) {
        console.error(error);        
      }
    },
    async GetTreeDataInitUser(){
      try{
        const { data } = await store.dispatch('app-user/getUsersHierarchy', {id: this.userIdLogged})
        const arrayClean = await this.limpiarData(data.children)
        console.log(arrayClean)
        const dataTree = [
          {
            id: data._id,
            title: data.title,
            expanded: true,
            async: true,
            children: arrayClean
          }
        ] 

        this.treeDataAsync = dataTree

      }catch(error){
        console.error(error)
      }
    },
    nodechecked(node, v) {
      // eslint-disable-next-line no-alert
      alert(`that a node-check envent ...${node.title}${v}`)
    },
    async asyncLoad(node) {

      console.log(node, "node")
      const { checked = false } = node
      this.$set(node, 'loading', true)

      try {
        const { data } = await store.dispatch('app-user/getUsersHierarchy', {id: node.id})
        const arrayClean = await this.limpiarData(data.children)
        console.log(arrayClean)
        const dataTree = [
          {
            id: data._id,
            title: data.title,
            expanded: true,
            async: true,
            children: arrayClean
          }
        ] 
        // eslint-disable-next-line no-prototype-builtins
        if (!node.hasOwnProperty('children')) {
          this.$set(node, 'children', [])
        }
        node.children.push(...arrayClean)
        this.$set(node, 'loading', false)
        if (checked) {
          this.$refs.treeAsync.childCheckedHandle(node, checked)
        }

        console.log(dataTree)
        // const children = value.find(element => element._id == node.id)
        // if (children) {
        //   this.$set(node, 'children', children.children)
        // }
      } catch (error) {
        console.error(error)
      }

      // const pro = await new Promise(resolve => {
      //   setTimeout(resolve, 500, [{ title: 'test1', async: true }, { title: 'test2', async: true }, { title: 'test3' }])
      // })

      
    },
  },
  created() {
    this.GetTreeDataInitUser()
  },

}
</script>
<style>
.tree-block{
  float: left;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 800px;
}
.tree-search-input{
  width: 70%;
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  border:1px solid #ccc;
}
.tree-search-btn{
  width: 25%;
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: rgb(218, 218, 218);
  border:1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>